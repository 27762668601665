import { toast } from 'react-toastify';
import Middleware from '@/graphql/middlewares/Middleware';

export default class ErrorsMiddleware extends Middleware {
  // eslint-disable-next-line class-methods-use-this
  afterRequest(operation, result) {
    if (result.errors) {
      result.errors.forEach((error) => {
        switch (error.extensions.category) {
          case 'graphql':
          case 'internal': {
            // Show generic error message
            toast.error('An unexpected error occurred while processing your request.', {
              toastId: operation.operationName,
            });
            break;
          }
          case 'validation':
            // Do nothing
            break;
          default: {
            // Show user-friendly message
            toast.error(error.message, {
              toastId: operation.operationName,
            });
          }
        }
      });
    } else {
      toast.dismiss(operation.operationName);
    }
  }
}
